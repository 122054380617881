<template>
  <div class="grid-1">
    <div>
      <h3>Conditions Générales d'Utilisation du Site Internet United For Animals</h3>
      <h3>Préambule</h3>
      <p>Les présentes Conditions Générales d'Utilisation (ci-après les "CGU") définissent les modalités d'accès et d'utilisation du site internet <a href="https://unitedforanimals.fr">unitedforanimals.fr</a> (ci-après le "Site"), exploité par l'association Unités For Animals (ci-après l' "Association").</p>
      <p>L'Association est une association loi 1901 à but non lucratif, ayant pour objet la protection des animaux.</p>
      <p>Elle organise notamment des événements de marathons de streaming sur la plateforme Twitch au profit d'associations de protection animale.</p>
      <h3>Article 1 - Acceptation des CGU</h3>
      <p>L'accès et l'utilisation du Site impliquent l'acceptation pleine et entière des présentes CGU par l'Utilisateur (ci-après défini).</p>
      <p>L'Utilisateur déclare avoir pris connaissance des informations portant sur l'Association, ses activités et ses missions, figurant sur le Site.</p>
      <h3>Article 2 - Accès au Site</h3>
      <p>Le Site est accessible à tout Utilisateur disposant d'une connexion internet et d'un matériel informatique permettant d'y accéder.</p>
      <p>L'accès au Site est gratuit.</p>
      <p>L'Association se réserve le droit de modifier, de suspendre ou d'interrompre tout ou partie du Site, sans préavis, sans indemnité et sans engagement de sa part.</p>
      <h3>Article 3 - Utilisation du Site</h3>
      <p>L'Utilisateur s'engage à utiliser le Site de manière conforme aux présentes CGU, aux lois et règlements en vigueur et aux droits des tiers.</p>
      <p>L'Utilisateur s'interdit notamment :</p>
      <p><ul>
        <li>D'utiliser le Site à des fins illégales, nuisibles, frauduleuses ou contraires à l'ordre public ou aux bonnes mœurs</li>
        <li>D'introduire sur le Site des éléments nuisibles tels que des virus, vers informatiques, chevaux de Troie, etc.</li>
        <li>D'altérer ou de modifier les informations ou les documents figurant sur le Site</li>
        <li>De porter atteinte aux droits des tiers tels que les droits de propriété intellectuelle, les droits à l'image, les droits au respect de la vie privée, etc.</li>
      </ul></p>
      <p>L'Association se réserve le droit de prendre toutes les mesures nécessaires pour faire cesser tout usage abusif du Site et de poursuivre les contrevenants en justice.</p>
      <h3>Article 4 - Contenus du Site</h3>
      <p>Les informations figurant sur le Site sont présentées à titre informatif.</p>
      <p>L'Association met tout en œuvre pour que les informations contenues sur le Site soient exactes et à jour, mais ne peut garantir l'exactitude, la complétude ou l'actualité de ces informations.</p>
      <p>L'Utilisateur est seul responsable de l'utilisation qu'il fait des informations contenues sur le Site.</p>
      <p>L'Association se réserve le droit de modifier, de supprimer ou d'ajouter des informations sur le Site sans préavis.</p>
      <h3>Article 5 - Liens hypertextes</h3>
      <p>Le Site peut contenir des liens hypertextes vers d'autres sites internet ou des ressources externes.</p>
      <p>L'Association ne contrôle pas ces sites internet et ressources externes et n'assume aucune responsabilité quant à leur contenu, leur accessibilité ou leur utilisation.</p>
      <p>L'Utilisateur est seul responsable de la consultation de ces sites internet et ressources externes.</p>
      <h3>Article 6 - Dons via StreamLabs</h3>
      <p>L'Utilisateur peut effectuer des dons à l'Association via l'intermédiaire de la plateforme StreamLabs.</p>
      <p>L'Utilisateur reconnaît avoir pris connaissance des conditions générales d'utilisation de StreamLabs avant d'effectuer un don.</p>
      <p>L'Association ne saurait être tenue responsable des dysfonctionnements de la plateforme StreamLabs ou des préjudices pouvant résulter de son utilisation.</p>
      <h3>Article 7 - Abonnements et achats de bits sur Twitch</h3>
      <p>L'association United For Animals tient à informer ses soutiens que les abonnements et les achats de bits effectués sur la plateforme Twitch ne sont pas reversés à l'association.</p>
      <p>En effet, les revenus générés par les abonnements et les bits sur Twitch sont conservés par la plateforme et ne font pas l'objet d'un versement à l'association.</p>
      <p>Si vous souhaitez soutenir l'association United For Animals, nous vous encourageons à le faire directement via les moyens suivants :</p>
      <ul>
        <li>Dons via le site internet : <a href="https://unitedforanimals.fr">unitedforanimals.fr</a></li>
        <li>Dons via la plateforme StreamLabs : <a href="https://streamlabscharity.com/teams/@unitedforanimals-2024/united-for-animal-2024?member=591870876056690220">Streamlabs</a></li>
      </ul>
      <h3>Article 8 - Boutique en ligne Myspreadshop</h3>
      <p>L'association United For Animals met à la disposition de ses soutiens une boutique en ligne hébergée sur la plateforme Myspreadshop.</p>
      <p>Cette boutique propose à la vente des produits dérivés de l'association, tels que des tee-shirts, des mugs, etc.</p>
      <p>L'association United For Animals tient à préciser qu'elle n'est pas responsable de la gestion de cette boutique en ligne.</p>
      <p>La plateforme Myspreadshop assure l'intégralité de la gestion de la boutique, depuis la création des produits jusqu'à la livraison aux clients.</p>
      <p>L'association United For Animals ne dispose d'aucun contrôle sur les prix, les délais de livraison ou la qualité des produits.</p>
      <p>En cas de litige relatif à une commande effectuée sur la boutique en ligne Myspreadshop, l'utilisateur devra s'adresser directement à la plateforme Myspreadshop.</p>
      <p>L'association United For Animals ne pourra en aucun cas être tenue responsable des préjudices directs ou indirects résultant d'une commande effectuée sur la boutique en ligne Myspreadshop.</p>
      <h3>Article 9 - Propriété intellectuelle</h3>
      <p>Le Site et tous ses éléments, notamment les textes, images, sons, vidéos, logos, marques, etc., sont protégés par le droit de la propriété intellectuelle.</p>
      <p>Toute reproduction, représentation, modification, adaptation, distribution ou diffusion, totale ou partielle, du Site ou de l'un quelconque de ses éléments, sans l'autorisation expresse et écrite de l'Association, est interdite.</p>
      <h3>Article 10 - Responsabilité</h3>
      <p>L'Association ne saurait être tenue responsable des dommages directs ou indirects résultant de l'utilisation du Site, notamment des dommages matériels, des pertes de données ou des préjudices financiers.</p>
      <p>L'Utilisateur est seul responsable de l'utilisation qu'il fait du Site et des conséquences qui en découlent.</p>
      <h3>Article 11 - Modification des CGU</h3>
      <p>L'Association se réserve le droit de modifier les présentes CGU à tout moment.</p>
      <p>Les nouvelles CGU seront applicables à compter de leur date de publication sur le Site.</p>
      <p>L'Utilisateur est invité à consulter régulièrement les CGU afin de prendre connaissance de leurs éventuelles modifications.</p>
      <p>En poursuivant sa navigation sur le Site après la publication des nouvelles CGU, l'Utilisateur déclare les accepter.</p>
    </div>
  </div>
</template>

<style></style>
