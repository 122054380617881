<template>
  <div class="header">Les clips Twitch</div>
  <div class="content">
    <nav class="button-list"><a v-for="s in this.years" :href="'/clips/' + s">{{ s }}</a></nav>
    <ul v-if="clips.totalPages" class="pagination">
      <li class="page-item">
        <a class="page-link" href="#">Précédent</a>
      </li>
      <li class="page-item" v-for="s in clips.totalPages" :id="s"><a class="page-link" :href="'?page=' + s">{{ s }}</a>
      </li>
      <li class="page-item">
        <a class="page-link" href="">Suivant</a>
      </li>
    </ul>
    <center v-else><p>Aucun clip n'a été créé pour l'instant.</p></center>
    <div class="grid">
      <div id="clips-card" class="contents" v-for="s in clips.data" @click="openModal(s.clip_id)">
        <div class="preview-image">
          <img id="foreground-image" :src="s.thumbnail_url">
        </div>
        <div class="clip-bottom">
          <div class="clip-avatar">
            <img class="twitch-avatar" :src="s.avatar">
          </div>
          <div class="clip-content">
            <div class="clip-title">{{ truncateString(s.title, 45) }} </div>
            <div class="clip-streamer">{{ s.broadcaster_name }}</div>
            <div class="clip-created-by">Clip créé par {{ s.creator_name }}</div>
          </div>
        </div>
      </div>
    </div>
    <ul v-if="clips.totalPages" class="pagination">
      <li class="page-item">
        <a class="page-link" href="#">Précédent</a>
      </li>
      <li class="page-item" v-for="s in clips.totalPages" :id="s"><a class="page-link" :href="'?page=' + s">{{ s }}</a>
      </li>
      <li class="page-item">
        <a class="page-link" href="">Suivant</a>
      </li>
    </ul>
    <div v-if="modalIsOpen" id="modal" @click="closeModal">
      <div class="modal-content">
        <div class="modal-body">
          <iframe :src="'https://clips.twitch.tv/embed?clip=' + this.clipid + '&parent=unitedforanimals.fr'" height="600"
            width="100%" allowfullscreen>
          </iframe>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  data: function () {
    return {
      clipid: "",
      currentPage: 1,
      years: ["2024", '2023'],
      clips: [],
      modalIsOpen: false,
    }
  },
  async mounted() {
    if (this.$route.query.page == undefined) {
      this.$route.query.page = 1
    }

    if (this.$route.params.years.length == 0) {
      this.$route.params.years = this.years[0]
    }

    if (this.$route.params.years.length == 0) {
      this.$route.params.years = this.years[0]
    }

    const response = await fetch(`https://api.unitedforanimals.fr/twitch/clip/${this.$route.params.years}/${this.$route.params.streamer}?page=${this.$route.query.page}`, {
      method: "GET"
    })
    this.clips = await response.json();

    if (this.$route.query.clipId != undefined) {
      this.openModal(this.$route.query.clipId)
    }

    this.currentPage = this.$route.query.page;

  },
  methods: {
    truncateString(text, maxLength) {
      if (text.length <= maxLength) {
        return text;
      }
      return text.substring(0, maxLength - 3) + "...";
    },
    openModal(event) {
      this.modalIsOpen = true;
      //document.querySelector('meta[name="description"]').setAttribute("content", _desc);
      //document.querySelector('meta[name="description"]').setAttribute("content", _desc);
      this.clipid = event
      this.$router.push({ path: this.$route.path, query: { clipId: event } });
    },
    closeModal() {
      this.modalIsOpen = false;
      this.$router.push({ path: this.$route.path, query: { page: this.currentPage } });
    },
    submitForm() {
      // Soumettre le formulaire
      this.closeModal();
    }
  }
}
</script>

<style>
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  padding-top: 10px;

  @media (max-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.modal-body>iframe {
  @media (max-width: 768px) {
    height: 300px;
  }
}

.grid>.contents {
  border: 1px solid #C8895E;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 5;
  justify-content: center;
  /* Add this line to center the items */
}

.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 5px 10px;
  border: 1px solid #ddd;
  text-decoration: none;
  color: white;
  background-color: #756BD6;
  transition: 0.3s;
}

.page-link:hover {
  background-color: #766bd69c;
}

.page-item.active .page-link {
  background-color: #666;
  color: #fff;
}

#modal {
  position: fixed;
  /* Positionnement absolu */
  top: 0;
  /* Début de la page */
  left: 0;
  /* Début de la page */
  width: 100%;
  /* Largeur totale */
  height: 100%;
  /* Hauteur totale */
  background-color: rgba(0, 0, 0, 0.4);
  /* Fond noir transparent */
  z-index: 100;
  /* Afficher au-dessus de tout le contenu */
}

.modal-content {
  background-color: #C8895E;
  margin: 15% auto;
  border-radius: 5px;
  /* width: 50%; */
  max-width: 80%;
  height: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
}

.modal-close-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.modal-button {
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 10px;
}

.modal-button:hover {
  background-color: #ddd;
}

#clips-card:hover {
  border-color: red;
  cursor: pointer;
}
</style>
