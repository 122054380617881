<template>
  <h3>La nouvelles éditions de l'événement caritatif pour les animaux revient bientôt sur Twitch !</h3>
  <h3>Préparez-vous à un week-end solidaire et divertissant !</h3>

  <h3>Au programme :</h3>

  <p> - Des streamers passionnés</p>
  <p> - Des jeux vidéo en live</p>
  <p> - Des défis et des animations</p>

  <p><span>Et bien plus encore !</span></p>

  <p><span>Tous ensemble, nous pouvons faire la différence pour les animaux !</span></p>

  <p><span>Suivez-nous pour ne rien manquer des dates et du programme.</span></p>

</template>

<script>
export default {

}
</script>

<style></style>