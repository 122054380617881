<template>
  <div class="grid-1">
    <div id="cagnotte">
      <h3 id="collect" align="center" @UpdateData="UpdateData($event)"></h3>
    </div>
  </div>
</template>

<script>
export default {
  async mounted() {
    const connection = new WebSocket("wss://ws.unitedforanimals.fr")

    connection.onmessage = ((message) => {
      const data = JSON.parse(message.data)

      

      if(data.type == "campaigns-data") {
        const formattedNumber = data.data.donation_collected.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          document.getElementById("collect").innerHTML = formattedNumber + " €"
      }
    })
  }
}
</script>

<style></style>