<template>
  <div class="events-live">
    <div class="div1">
      <div class="header">Un immense merci à tous les streamers !
      </div>
      <div class="content" align="center">
        <a v-for="s in this.events.streamers" :id="s.username"><img v-if="s.stream.title" @click="UpdateStream(s)"
            class="twitch-avatar liveup" :src="s.avatar">
          <img v-else @click="UpdateStream(s)" class="twitch-avatar" :src="s.avatar"></a>
      </div>
    </div>
    <div class="div2">
      <iframe id="twitch-player" src="https://player.twitch.tv/?channel=neoz24&parent=unitedforanimals.fr" height="100%"
        width="100%" allowfullscreen>
      </iframe>
    </div>
    <div class="div3" id="donation_goal">
      <div class="header">Donation Goals</div>
      <div v-for="d in this.currentStream.donationGoal" class="container" id="background-color">
        <p v-if="parseFloat(this.currentStream.amount) >= parseFloat(d.amount)" id="valide" align="center">{{
          parseFloat(d.amount) }} € - {{ d.title }} </p>
        <p v-else align="center">{{ d.amount }} € - {{ d.title }} </p>
      </div>
      <div class="header">Cagnotte personelle</div>
      <div class="content">
        <div class="gauge-container">
          <div class="gauge">
            <div class="progress-bar"></div>
            <div class="text-container">
              <span class="current-value"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <a class="button-donate" :href="this.currentStream.streamlabs_links" target="_blank">Faire un don</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      currentValue: Number = 0,
      maxValue: Number = 1000,
      events: Object,
      currentStream: 9999,
      streamers: [],
      connection: WebSocket,
    }
  },
  methods: {
    UpdateStream(data) {
      this.currentStream = data;

      document.getElementById("twitch-player").src = `https://player.twitch.tv/?channel=${data.username}&parent=unitedforanimals.fr`

      this.updateProgress(data.amount)
    },
    UpdateData(data) {
      this.events = data
    },
    updateProgress(newValue) {
      const progressBarElement = document.querySelector('.progress-bar');
      const currentValueElement = document.querySelector('.current-value');
      const maxValueElement = document.querySelector('.max-value');
      this.currentValue = newValue;
      const progressPercent = (this.currentValue / this.maxValue) * 100;

      progressBarElement.style.width = `${progressPercent}%`;
      currentValueElement.textContent = `${this.currentValue} €`;
    }
  },
  async unmounted() {
    this.connection.close();
  },
  async mounted() {
    this.connection = new WebSocket("wss://ws.unitedforanimals.fr")

    this.connection.onmessage = ((message) => {
      const data = JSON.parse(message.data)
      if (data.type == "campaigns-data") {
        this.events = data.data
        if (this.currentStream == 9999) {
          this.UpdateStream(this.events.streamers[0])
        }
      }
    })

    /*    
        async function GetData() {
          const response = await fetch("http://api.unitedforanimals.fr/events/stats/viewers");
          const data = await response.json()
          return data;
        }
    
        this.events = await GetData()
    
        setInterval(async () => {
          this.events = await GetData()
        }, 1000 * 10);
    
        this.UpdateStream(this.events.streamers[0])*/

  }
}
</script>

<style>
.twitch-player {
  min-height: 400px;
}

.button-donate {
  display:block;
  width: 50%;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  padding: 10px 10px;
  font-size: 25px;
  background-color: #756BD6; 
  color: white;
  text-decoration: none;
  border: none;
  cursor: pointer; 
  margin: auto;
}

.button-donate:hover { /* Effet au survol */
  background-color: #766bd6d2; /* Couleur de fond au survol */
}
</style>