<template>
  <div class="stats-card">
    <div class="div1">
      <div class="title">Spectacteurs</div>
      <div class="content" id="content-current-viewers">0000</div>
    </div>
    <div class="div2">
      <div class="title">Moyenne Spectacteurs</div>
      <div class="content" id="content-average-viewers">0000</div>
    </div>
    <div class="div3">
      <div class="title">Record de Spectacteurs</div>
      <div class="content" id="content-record-viewers">0000</div>
    </div>
  </div>
  <div class="grid-1">
    <div>
      <ag-charts-vue :options="optionsviewers"> </ag-charts-vue>
    </div>
  </div>
</template>
<style>
.stats-card {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}

.stats-card>div>.title {
  text-align: center;
  padding: 5px;
}

.stats-card>div>.content {
  text-align: center;
}

.stats-card>.div1 {
  margin-left: 10px;
  grid-area: 1 / 1 / 2 / 2;
  border: 1px solid #C8895E;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}

.stats-card>.div2 {
  grid-area: 1 / 2 / 2 / 3;
  border: 1px solid #C8895E;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}

.stats-card>.div3 {
  margin-right: 10px;
  grid-area: 1 / 3 / 2 / 4;
  border: 1px solid #C8895E;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}
</style>
<script>
import { AgChartsVue } from 'ag-charts-vue3';
import { onMounted, ref } from 'vue';

export default {
  name: 'StatsView',
  components: {
    AgChartsVue,
  },
  setup() {
    const optionsviewers = ref({
      title: {
        text: "Nombre de spectateur",
      },
      footnote: {
        text: "Historique du nombre de spectateur global (24heures)",
      }, theme: {
        overrides: {
          line: {
            series: {
              marker: {
                enabled: false,
              }
            },
          },
        },
      },
      background: {
        visible: false,
      },
      overlays: {
        loading: {
          text: 'Chargement des données en cours',
        },
        noData: {
          text: 'Aucune donnée disponible',
        },
        noVisibleSeries: {
          text: 'Aucune donnée disponible',
        },
      },
      data: [],
      axes: [
        {
          type: "time",
          position: "bottom",
          tick: {
            interval: 60
          }
        },
        {
          type: "number",
          position: "left",
          title: {
            text: "Nombre de spectateur",
          },
          tick: {
            interval: 10
          },
        },
      ],
      series: [
        {
          type: 'line',
          xKey: '_id',
          yKey: 'count'
        }],
    });

    return {
      optionsviewers
    };
  },
  mounted: async function () {
    const connection = new WebSocket("wss://ws.unitedforanimals.fr")
    connection.onmessage = ((message) => {
      const data = JSON.parse(message.data)
      if (data.type == "campaigns-data") {
        console.log(data.data.viewers)
        document.getElementById("content-current-viewers").innerHTML = data.data.viewers.current
        document.getElementById("content-average-viewers").innerHTML = data.data.viewers.average
        document.getElementById("content-record-viewers").innerHTML = data.data?.viewers?.record?.count ?? 0;

      }
      if (data.type == "campaigns-stats") {
        this.optionsviewers.data = data.data.map((item) => {
          const date = new Date(item._id);
          return { ...item, _id: date };
        });
      }
    })
  }
}
</script>