<template>
    <div class="banner">
        <a href="/"><img src="https://s3.pub1.infomaniak.cloud/object/v1/AUTH_8da80d6522534466b8217ed329c7cf38/UFA-2023/logo_png.png" class="logo">
</a>    </div>
</template>

<script>
export default {

}
</script>

<style>


.banner {
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  background-image: url("../assets/fond.png");
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 1);
}

.banner> .background {
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: absolute;
  z-index: 1;
}
.banner>a {
  display: block;
  margin: auto;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  width: calc(200px - 35px);
  object-fit: contain;
  filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.5));
  background-clip: content-box;
}

.banner>a:hover {
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.5));
}

.banner>a>.logo {
  display: block;
  margin: auto;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  width: calc(200px - 35px);
  margin-top: 15px;
  object-fit: contain;
  filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.5));
  background-clip: content-box;
  /*box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);*/
}
</style>