<template>
  <div class="test">
    <div class="div1">
      <h3>Des gamers au grand cœur : Jouer pour la bonne cause !</h3>
      <h3>Qui sommes-nous ?</h3>
      <p>Notre association est née de la passion commune d'un groupe de streamers et streameuses pour le jeu vidéo et
        l'envie de faire une différence dans le monde. Notre mission ?</p>
      <p>Organiser des événements caritatifs sur Twitch afin de récolter des fonds pour des associations et refuges
        œuvrant pour la protection des animaux.</p>

      <h3>Notre mission : Donner du sens au jeu vidéo</h3>

      <p>Chaque année, nous organisons un événement caritatif d'envergure sur trois jours.</p>
      <p>Durant ce marathon de streaming, des dizaines de streamers se relaient pour jouer à leurs jeux préférés et
        interagir avec leur communauté.</p>
      <p>L'objectif est simple : divertir et sensibiliser le public à la cause animale tout en récoltant un maximum de
        dons.</p>

      <h3>Comment nous soutenir ?</h3>

      <p>Faire un don : Même un petit don peut faire une grande différence pour les animaux en détresse. </p>
      <p>Vous pouvez faire un don ponctuel ou régulier via notre site web ou directement sur Twitch lors de nos
        événements.</p>

      <p>Participer à nos événements : Suivez-nous sur les réseaux sociaux pour ne rien manquer de nos événements
        caritatifs et venez nous soutenir en direct !</p>
      <p>Parlez-en autour de vous : Sensibilisez vos amis, votre famille et vos collègues à la cause animale et faites
        connaître notre association. Plus nous serons nombreux, plus nous aurons d'impact !
      </p>
      <p>Suivez-nous sur les réseaux sociaux : Restez informés de nos actions et de l'actualité de la cause animale en
        nous suivant sur Twitch, Twitter et Facebook.</p>

      <h3>Rejoignez-nous !</h3>

      <h3>Ensemble, nous pouvons changer la vie des animaux en détresse. Soutenez notre association et participez à nos
        événements caritatifs !</h3>
    </div>
    <div class="div2">
      <center> <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FAssociationCoupdeCoeurAnimal%2F&tabs=timeline&width=500&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
          width="500" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></center>
    </div>
  </div>
  <div class="grid-1">
    <div>
      <h3>Un succès incroyable !</h3>
      <p>Grâce à vous, notre événement caritatif 2023 a permis de récolter <span>2 050 €</span> pour le refuge Coup de
        Coeur Animal.</p>

      <p>Un immense merci aux streamers, donateurs et bénévoles qui ont contribué à ce résultat extraordinaire !</p>
      <p>Votre générosité permettra d'améliorer la vie des animaux du refuge.</p>
      <p>Continuons à nous mobiliser pour la cause animale !</p>
    </div>
  </div>
</template>
<style>
.test {
  display: grid;
  grid-template-columns: 1fr;  /* Single column for phones */
  grid-template-rows: auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.test>.div1 {
  margin: 10px;  /* Consistent margin for both elements */
  border: 1px solid #C8895E;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  padding: 8px;
}
.test>.div2 {
  margin: 10px;  /* Consistent margin for both elements */
}

/* Optional media query for larger screens (tablets and desktops) */
@media (min-width: 1200px) {
  .test {
    grid-template-columns: auto auto; /* Restore original layout for larger screens */
  }
}


</style>
<script>
import AvatarComponent from '@/components/AvatarComponent.vue'
export default {
  components: {
    AvatarComponent
  },
  data: function () {
    return {
      streamers: [
        { "name": "neoz24", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/9ebf6865-0a25-4416-a8ce-3dcc3b662d95-profile_image-70x70.jpeg" },
        { "name": "Narzaal", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/25808793-d355-4f5a-8408-8fbe35e8f76c-profile_image-70x70.png" },
        { "name": "piikashall", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/1d397f30-f7c9-450f-8b20-8fe8bb51edfc-profile_image-70x70.png" },
        { "name": "Neshtai", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/b2b5cc26-8301-4b46-b494-2af4c78390d3-profile_image-70x70.png" },
        { "name": "Tripo_TV", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/4295ffff-bc90-4538-a783-a7c5955fddff-profile_image-70x70.jpeg" },
        { "name": "nastaxz", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/fdbdebfc-4ec7-4b48-b4d1-11ac1156b879-profile_image-70x70.png" },
        { "name": "Onikama", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/7562cf50-64f5-4e69-8ce6-6cefdfaff72e-profile_image-70x70.png" },
        { "name": "Orysea", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/637af926-c3c5-4bac-b1d3-b317ba100870-profile_image-70x70.jpeg" },
        { "name": "HiroSaga", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/e2806f31-ac66-4fb7-8bb6-db37ce603dbb-profile_image-70x70.png" },
        { "name": "Chapo__TV", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/75ac8674-0bfb-492f-b7df-ad6d5ce981d0-profile_image-70x70.png" },
        { "name": "alexwill1993", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/b45ff322-60a2-4525-862b-25bc4735fede-profile_image-70x70.jpeg" }
      ]
    }
  }
}
</script>