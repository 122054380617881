<template>
  <EventLiveComponent />
</template>

<script>
import LastEvent from '@/components/LastEvents.vue'
import YoutubeComponent from '@/components/YoutubeComponent.vue'
import NewsComponents from '@/components/NewsComponents.vue'
import AvatarComponent from '@/components/AvatarComponent.vue'
import EventLiveComponent from '@/components/EventLiveComponent'

export default {
  name: 'HomeView',
  components: {
    LastEvent,
    YoutubeComponent,
    NewsComponents,
    AvatarComponent,
    EventLiveComponent
  },
  data: function () {
    return {
      connection: null
    }
  },
  mounted: function () {
  }
}
</script>

<style>
#cagnotte {
  border-image: url("../assets/fond.png") 1 10 stretch stretch;
  border-width: 3px;
  /* Épaisseur de la bordure */
  border-style: solid;
  /* Style de bordure de secours */
}

.grid-2 {
  height: 100%;
}

#collect {
  font-size: 50px;
  margin-block-end: 0.1em;
}

@media (max-width: 768px) {
  #collect {
    font-size: 2em;
  }
}

#valide {
  opacity: 0.5;
  text-decoration: line-through;
}

#donation_goal>div>p {
  opacity: 0.7;
  color: black;
  background-color: #756BD6;
  border-radius: 10px;
}

#donation_goal>div>#valide {
  opacity: 0.5;
  text-decoration: line-through;
}

.events-live {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}

.events-live>div {
  margin: 10px;
  border: 1px solid #C8895E;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}
.events-live>.div>.img {
  width: 100%;
  height: 50px;
}
@media (max-width: 968px) {
  .events-live {
    display: unset;
    flex-wrap: wrap;
    margin: 10px;
  }
}

@media (min-width: 968px) {
  .events-live {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

iframe {
  border-radius: 30px;
}

.div1 {
  position: relative;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  /* Align to the left edge of the footer */
  right: 0;
  padding: 5px;
  text-align: center
}

.gauge-container {
  width: 100%;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}

.gauge {
  width: 100%;
  height: 50px;
  background-color: #E1A882;
  /* Red background */
  position: relative;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #756BD6;
  /* Green progress bar */
  transition: width 0.5s ease;
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  /* White text */
}

.current-value,
.max-value {
  font-size: 40px;
  font-weight: bold;
  color: white;
}

.twitch-avatar.liveup {
  border: 3px solid red;
}

.twitch-avatar {
  border: 0px solid red;
}

.twitch-avatar:hover {
  cursor: pointer;
  border: 4px solid red;
}

/* Style de base pour les div */
.twitch-content>.div {
  width: 20%;
  /* Largeur de chaque div (environ 20% de la largeur du conteneur) */
  margin: 5px;
  /* Marge entre les div */
  box-sizing: border-box;
  float: left;
}

.twitch-content>div {
  height: 100%;
  width: 100%;
  background-color: #5545e4
}
/* Style spécifique pour les petites tailles d'écran (mobile) */
@media (max-width: 768px) {
  .twitch-content>.div {
    width: 33%;
    /* Largeur de chaque div sur mobile (50% de la largeur du conteneur) */
  }
}

/* Style pour le conteneur principal */
.twitch-content {
  width: auto;
  padding: 10px;
  overflow: hidden; 
  background-color: red;
}
</style>