<template>
  <nav class="menu">
    <router-link to="/">Accueil</router-link>
    <a href="https://streamlabscharity.com/teams/@unitedforanimals-2024/united-for-animal-2024?member=591870876056690220" target="_blank" class="">Faire un don</a>
    <a href="https://ufa-shop.myspreadshop.fr/" target="_blank" class="">La boutique</a>
    <router-link to="/editions">L'association</router-link>
    <router-link to="/clips">LES CLIPS</router-link>
    <!--<router-link to="/contact">Contact</router-link>-->
  </nav>
</template>

<script>
export default {

}
</script>

<style>
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 20px;
  /*width: 80%;*/
  max-width: 900px;
  /* Adjust for larger screens if needed */
  background-color: #FEE9D6;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}

.menu a {
  text-decoration: none;
  color: #756BD6;
  font-size: 1.5em;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 0.3s ease-in-out all;
  margin: 5px;
}

.menu a:hover {
  color: #fff;
  background-color: #756BD6;
}

@media (max-width: 768px) {
  .menu {
    flex-direction: column; /* Stack buttons vertically */
    width: 100%; /* Stretch to full width */
    max-width: unset; /* Remove max-width for smaller screens */
  }

  .menu a {
    margin: 5px 0; /* Adjust margin for vertical stacking */
  }
}
</style>