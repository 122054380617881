<template>
  <iframe id="youtube-player" width="100%" height="100%" src="https://www.youtube.com/embed/2S2-6KOZNXA" frameborder="0"
    allowfullscreen></iframe>
</template>

<script>
export default {

}
</script>

<style></style>