<template>
    <div class="content">
        <div class="grid">
            <div id="clips-card" class="contents">
                <center>
                    <p></p>
                    <p>Moyenne de viewers</p>
                    <p></p>
                    <p id="content-average-viewers"></p>
                </center>
            </div>
            <div id="clips-card" class="contents">
                <center>
                    <p></p>
                    <p>Record de viewers</p>
                    <p id="content-record-viewers"></p>
                    <p></p>
                </center>
            </div>
            <div id="clips-card" class="contents">
                <center>
                    <p></p>
                    <p>Nombre de donations</p>
                    <p>{{ donation_count }}</p>
                </center>
            </div>
        </div>
    </div>
    <div class="content">
        <h3>Les plus grands contributeurs</h3>
        <div class="grid">
            <div id="clips-card" class="contents">
                <ul>
                    <li v-for="s in streamers_1">{{ s.username }} ({{ s.amount }} €)</li>
                </ul>
            </div>
            <div id="clips-card" class="contents">
                <ul>
                    <li v-for="s in streamers_2">{{ s.username }} ({{ s.amount }} €)</li>
                </ul>
            </div>
            <div id="clips-card" class="contents">
                <ul>
                    <li v-for="s in streamers_3">{{ s.username }} ({{ s.amount }} €)</li>
                </ul>
            </div>
            <div id="clips-card" class="contents">
                <ul>
                    <li v-for="s in streamers_4">{{ s.username }} ({{ s.amount }} €)</li>
                </ul>
            </div>
        </div>

    </div>
</template>

<style></style>

<script>
export default {
    data: function () {
        return {
            streamers_1: [],
            streamers_2: [],
            streamers_3: [],
            streamers_4: [],
            donation_count: 0
        }
    },
    async mounted() {
        const leaderboardsd = await fetch('https://api.unitedforanimals.fr/events/leaderboard')
        const leaderboard = await leaderboardsd.json();
        this.donation_count = leaderboard.donation_count
        leaderboard.top_donor.slice(0, 20).forEach(element => {
            this.streamers_1.push({
                username: element._id,
                amount: element.totalAmount.$numberDecimal
            })
        });
        leaderboard.top_donor.slice(20, 40).forEach(element => {
            this.streamers_2.push({
                username: element._id,
                amount: element.totalAmount.$numberDecimal
            })
        });
        leaderboard.top_donor.slice(40, 60).forEach(element => {
            this.streamers_3.push({
                username: element._id,
                amount: element.totalAmount.$numberDecimal
            })
        });
        leaderboard.top_donor.slice(60, 80).forEach(element => {
            this.streamers_4.push({
                username: element._id,
                amount: element.totalAmount.$numberDecimal
            })
        });

        const connection = new WebSocket("wss://ws.unitedforanimals.fr")
        connection.onmessage = ((message) => {
            const data = JSON.parse(message.data)
            if (data.type == "campaigns-data") {
                //document.getElementById("content-current-viewers").innerHTML = data.data.viewers.current
                document.getElementById("content-average-viewers").innerHTML = data.data.viewers.average
                document.getElementById("content-record-viewers").innerHTML = data.data?.viewers?.record?.count ?? 0;

            }
            /*if (data.type == "campaigns-stats") {
                this.optionsviewers.data = data.data.map((item) => {
                    const date = new Date(item._id);
                    return { ...item, _id: date };
                });
            }*/
        })

    }
}
</script>