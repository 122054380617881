<template>
  <h3>Edition 2023</h3>
  <p>Grâce à la générosité de notre communauté, nous avons pu récolter <b><span>2 050 €</span></b> pour le refuge <span>Coup de Coeur Animal</span></p>
  <h3>Un grand merci à tous les participants !</h3>
  <p align="center">
    <AvatarComponent v-for="s in streamers" :avatar_uri="s.avatar_uri" :name="s.name"/>
  </p>
  <h3>Merci à tous pour votre générosité !</h3>
</template>

<script>
import AvatarComponent from '@/components/AvatarComponent.vue'
export default {
  components: {
    AvatarComponent
  },
  data: function () {
    return {
      streamers: [
        { "name": "neoz24", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/9ebf6865-0a25-4416-a8ce-3dcc3b662d95-profile_image-70x70.jpeg" },
        { "name": "Narzaal", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/25808793-d355-4f5a-8408-8fbe35e8f76c-profile_image-70x70.png" },
        { "name": "piikashall", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/1d397f30-f7c9-450f-8b20-8fe8bb51edfc-profile_image-70x70.png" },
        { "name": "Neshtai", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/b2b5cc26-8301-4b46-b494-2af4c78390d3-profile_image-70x70.png" },
        { "name": "Tripo_TV", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/4295ffff-bc90-4538-a783-a7c5955fddff-profile_image-70x70.jpeg" },
        { "name": "nastaxz", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/fdbdebfc-4ec7-4b48-b4d1-11ac1156b879-profile_image-70x70.png" },
        { "name": "Onikama", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/7562cf50-64f5-4e69-8ce6-6cefdfaff72e-profile_image-70x70.png" },
        { "name": "Orysea", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/637af926-c3c5-4bac-b1d3-b317ba100870-profile_image-70x70.jpeg" },
        { "name": "HiroSaga", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/e2806f31-ac66-4fb7-8bb6-db37ce603dbb-profile_image-70x70.png" },
        { "name": "Chapo__TV", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/75ac8674-0bfb-492f-b7df-ad6d5ce981d0-profile_image-70x70.png" },
        { "name": "alexwill1993", "avatar_uri": "https://static-cdn.jtvnw.net/jtv_user_pictures/b45ff322-60a2-4525-862b-25bc4735fede-profile_image-70x70.jpeg" }
      ]
    }
  }
}
</script>

<style></style>