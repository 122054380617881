import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EditionView from '../views/EditionView'
import DonateView from '../views/DonateView.vue'
import ClipsView from '@/views/ClipsView.vue'
import StatsView from '@/views/StatsView.vue'
import CguView from '@/views/cgu.vue'

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: HomeView,
    meta: {
      title: 'Accueil',
    },
  },
  {
    path: '/editions',
    name: 'Editions',
    component: EditionView,
    meta: {
      title: 'L\'association',
    },
  },
  {
    path: '/clips/:years(\\d+)?/:streamer?',
    component: ClipsView,
    meta: {
      title: 'Les clips',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }, {
    path: '/stats',
    component: StatsView
  },
  {
    path: '/cgu',
    component: CguView
  }, {
    path: '/private2024',
    component: DonateView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  document.title = 'United For Animals - ' + to.meta?.title 
})

export default router
