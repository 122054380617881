<template>
  <a :id="name" ><img class="twitch-avatar" :src="data.avatar"></a>
</template>

<script>
export default {
  props: ['data'],
  setup(props) {
    return props
  },
  mounted() {
    console.log(this.data)
    //document.getElementById(this.name).href = "https://www.twitch.tv/" + this.name
  }
}
</script>

<style>
.twitch-avatar {
  max-height: 70px;
}
</style>