<script>
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import DonationCollected from './components/DonationCollected.vue'

export default {
  components: {
    Header,
    Menu,
    DonationCollected
  }
}
</script>

<template>
  <Header />
  <Menu />
  <div class="container">
    <DonationCollected />
    <router-view />
  </div>
  <br>
  <center><p>&copy; 2024 <a href="https://unitedforanimals.fr/">United For Animals</a>. Tous droits réservés. <router-link to="/cgu">Conditions d'utilisation.</router-link></p></center>
</template>

<style>
body {
  background-color: #E1A882;
  margin: 0;
  font-family: "Paratha", sans-serif;
}

@font-face {
  font-family: "Paratha";
  src: url("./assets/Avilock Bold.ttf") format("truetype");
}

span {
  color: #5545e4;
}


.twitch-avatar {
  border-radius: 20%;
  margin: 3px;
  box-sizing: border-box;
  background-color: #DDD;
  border: 3px solid #756BD6;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  transition: 0.3s ease-in-out all;
}

.twitch-avatar:hover {
  border: 3px solid red;
}

h3 {
  color: #756BD6;
}

h3,
p {
  margin-block-start: 0em;
  margin-block-end: 0.5em;
}

.grid-1 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.grid-1>div,
.grid-2>div,
.col-3>div {
  padding: 8px;
}

.grid-1>div,
.grid-2>div,
.col-3>div,
.col-4>div {
  margin: 10px;
  border: 1px solid #C8895E;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-row-gap: 0px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

iframe {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}

.col-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Affichage en deux colonnes sur les écrans larges */
  grid-template-rows: 1fr;
  grid-row-gap: 0px;

  /* Modification pour la version mobile : forcer une seule colonne */
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1200px) {
  #youtube-player {
    height: 350px;
  }
}

@media (max-width: 768px) {
  #youtube-player {
    height: 200px;
  }
}


.col-3>div {
  margin: 10px;
  border: 1px solid #C8895E;
  border-radius: 10px;
  padding: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}

h3,
a {
  font-family: "Paratha", sans-serif;
  text-transform: uppercase;
}

p,
button {
  color: black;
  font-family: "Paratha", sans-serif;
}

.container {
  margin-left: 50px;
  margin-right: 50px;
  background-color: #FEE9D6;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}

.col-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

}

.col-4>.div1 {
  grid-area: 1 / 1 / 2 / 2;
}

.col-4>.div2 {
  grid-area: 1 / 2 / 2 / 3;
}

.col-4>.div3 {
  grid-area: 1 / 3 / 2 / 4;
}

.col-4>.div4 {
  grid-area: 1 / 4 / 2 / 5;
}

/* Media query for smaller screens */
@media (max-width: 768px) {

  .container {
    margin-left: 2px;
    margin-right: 2px;
  }
}

button {
  background-color: #756BD6;
  color: white;
  font-size: 25px;
}

button:hover {
  background-color: #FEE9D6;
  color: #756BD6;
  cursor: copy;
}



/* Media query to target mobile devices */
@media (max-width: 900px) {

  .col-4 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    /* Responsive column sizing */
    grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
    /* Responsive row sizing (optional) */
    grid-gap: 10px;
    /* Maintain consistent gap */
  }

  /* Reset grid-area styles for mobile layout (if needed) */
  .col-4>.div1,
  .col-4>.div2,
  .col-4>.div3,
  .col-4>.div4 {
    grid-area: initial;
    /* Remove explicit grid-area definitions */
  }

  .col-4 {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }
}

@media (max-width: 768px) {

  .col-4 {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
}

.preview-image {
  width: 100%;
  padding-bottom: 10px;
}

.preview-image>img {
  width: 100%;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
}

.clip-title,
.clip-streamer,
.clip-created-by {
  color: #756BD6;
  font-size: 0.8rem;
  font-family: "Paratha", sans-serif;
}

.clip-bottom {
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.clip-avatar {
  grid-area: 1 / 1 / 2 / 2;
  padding: 5px;
}

.clip-content {
  padding: 5px;
  grid-area: 1 / 2 / 2 / 3;
}

clip-title {
  height: auto;
}

.clip-streamer,
.clip-created-by {
  height: auto;
}

.clip-title {
  font-size: 1em;
}

.clip-streamer,
.clip-created-by {
  color: #766bd6a6;
}

.clip-bottom>.clip-avatar>img {
  width: 100%;
  margin: 0px;
}

.header {
  padding-left: 10px;
  padding-top: 10px;
  font-family: "Paratha", sans-serif;
  color: #756BD6;
  text-transform: uppercase;
  font-size: 1.5em;
}

.content {
  padding: 10px;
}

.button-list>a {
  color: #fff;
  background-color: #756BD6;
  padding: 5px;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 1.2em;
  text-decoration: none;
  transition: 0.3s ease-in-out all;
}

.button-list>a:hover {
  background-color: #E1A882;
}

.button-list {}
</style>